/* Apply scrollbar styles to all elements, not just body */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
